<template>
  <div>
    <el-table :data="shoppingList"
              ref="multipleTable"
              style="width: 100%"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection"
                       width="55">
      </el-table-column>
      <el-table-column prop="product_icon"
                       :label="$t('shopping.tp')"
                       width="270"
                       align="center">
        <template slot-scope="scope">
          <el-popover placement="right"
                      title=""
                      trigger="hover">
            <img :src="scope.row.product_icon"
                 style="height: 650px;width: 830px" />
            <img slot="reference"
                 :src="scope.row.product_icon"
                 :alt="scope.row.product_icon"
                 style="max-height: 50px;max-width: 130px">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column :label="$t('shopping.spmc')"
                       prop="product_name"
                       width="300">
      </el-table-column>
      <el-table-column :label="$t('shopping.dj')"
                       prop="product_price"
                       width="200">
      </el-table-column>
      <el-table-column :label="$t('shopping.xj')"
                       prop="subtotal"
                       width="200">
      </el-table-column>
      <el-table-column :label="$t('shopping.sl')"
                       width="180px"
                       align="center"
                       prop="product_num">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.product_num"
                           size="small"
                           @change="handleCareOrderItemNumChange(scope.row)"
                           :min="1"
                           :max="10000" />
        </template>
      </el-table-column>
      <el-table-column fixed="right"
                       :label="$t('shopping.cz')"
                       width="50">
        <template slot-scope="scope">
          <i class="el-icon-close"
             @click="handleDelete(scope.$index, scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
    <div class="pre_order">
      <div class="tips">
        <ul>
          <li class="continue"
              @click="continues">{{$t('shopping.jxll')}}</li>
          <li>|</li>
          <li>{{$t('shopping.gong')}}<span>{{totalNum}}</span>{{$t('shopping.jcp')}}</li>
        </ul>
      </div>
      <div class="order_div">
        <div class="order_total">{{$t('shopping.hj')}}：<span>{{totalPrice}}</span></div>
        <el-button type="primary"
                   @click="settlement">{{$t('shopping.qjs')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shopping',

  data () {
    return {
      shoppingList: [],
      num: 0,
      totalNum: 0,
      totalPrice: 0,
      multipleSelection: []
    };
  },

  mounted () {
    this.addDataList()
  },

  methods: {
    async addDataList () {
      let shoppingList = await this.api.get(this.apiPath.shoppingList, {});
      this.shoppingList = shoppingList
      console.log(this.shoppingList)
      this.totalNum = shoppingList.length
      this.shoppingList.forEach(item => {
        var m = 0,
          s1 = item.product_price.toString(),
          s2 = item.product_num.toString();
        try {
          m += s1.split(".")[1].length
        } catch (e) { }
        try {
          m += s2.split(".")[1].length
        } catch (e) { }
        item.subtotal = Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
      })
      this.accAdd(shoppingList)
      this.$nextTick(() => {
        for (let i = 0; i < this.shoppingList.length; i++) {
          if (this.shoppingList[i].check) {
            this.$refs.multipleTable.toggleRowSelection(this.shoppingList[i]);
          }
        }
      })
    },
    accAdd (arr) {
      const fieldArr = []
      for (var i = 0; i < arr.length; i++) {
        var m, field
        try {
          field = arr[i].subtotal.toString().split('.')[1].length
        } catch (e) {
          field = 0
        }
        fieldArr.push(field)
      }
      m = Math.pow(10, Math.max(...fieldArr))
      let add = 0
      arr.forEach(function (item) {
        add = add + item.subtotal * m
      })
      const sum = m > 0 ? Math.round(add) / m : 0
      this.totalPrice = sum
    },
    async handleDelete (index, row) {
      // 删除购物车某一件商品
      this.$confirm(this.$t('shopping.cczjycgsp'), this.$t('shopping.ts'), {
        confirmButtonText: this.$t('shopping.qr'),
        cancelButtonText: this.$t('shopping.qx'),
        type: 'warning'
      }).then(() => {
        this.deleteGoods(row)
        let num = Math.round(Math.random() * 1100)
        localStorage.setItem("shoppingNum", num);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('shopping.yqxyc')
        });
      });
    },
    async deleteGoods (row) {
      let data = {
        code: row.product_id,
      }
      let res = await this.api.post(this.apiPath.delCart, data);
      if (res.code == 3) {
        this.$message({
          message: this.$t('shopping.ycspcg'),
          type: 'success'
        });
        this.addDataList()
      } else {
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
    async handleCareOrderItemNumChange (value) {
      //修改某一件商品数量
      let data = {
        code: value.product_id,
        num: value.product_num
      }
      let res = await this.api.post(this.apiPath.updateCartNum, data);
      if (res.code == 2) {
        this.$message({
          message: this.$t('shopping.xgslcg'),
          type: 'success'
        });
        this.totalNum = 0
        this.totalPrice = 0
        this.addDataList()
      } else {
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
    settlement () {
      localStorage.setItem("buyType", 1);

      // 结算
      if (this.multipleSelection.length) {
        localStorage.setItem("commodityInfo", JSON.stringify(this.multipleSelection));
        console.log(this.multipleSelection);

        this.$router.push({
          name: 'pay',
        });
      } else {
        this.$message({
          message: this.$t('shopping.qxznyjsdsp'),
          type: 'warning'
        });
      }
    },
    async handleSelectionChange (val) {
      //购物车选中状态
      let data = {
        codes: []
      }
      val.forEach(item => {
        data.codes.push(item.product_id)
      })
      console.log(val);
      this.multipleSelection = val;
      this.accAdd(val)
      let res = await this.api.post(this.apiPath.checkSome, data);
      if (res.code >= 200) {
        this.$message({
          message: res.message,
          type: 'warning'
        });
      }
    },
    continues () {
      this.$router.push("/product");
    }
  },
};
</script>

<style lang="less" scoped>
.el-icon-close {
  cursor: pointer;
  font-size: 24px;
}
.pre_order {
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tips {
    ul {
      display: flex;
      .continue {
        cursor: pointer;
        padding-left: 0;
      }
      li {
        font-size: 14px;
        display: inline-block;
        padding: 0 10px;
        color: #666;
        span {
          color: #1baeae;
          margin: 0 4px;
        }
      }
    }
  }
  .order_div {
    // width: 300px;
    font-size: 14px;
    color: #1baeae;
    display: flex;
    align-items: center;
    .order_total {
      margin-right: 15px;
    }
  }
}
</style>